import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { communityService } from '~/services/community';

const useCommunityCategories = (
  options: Partial<{
    lazy: boolean;
    server: boolean;
  }> = {},
) => {
  return useAsyncDataWrapper(
    ASYNC_DATA_KEYS['/v1/user/community/category'](),
    async () => {
      const { data } = await communityService.getCommunityCategories();

      return data.items;
    },
    { ...options },
  );
};

export default useCommunityCategories;
